import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, InputNumber, DatePicker,Space,Spin} from 'antd';
import moment from 'moment';
import 'moment/locale/es'; 
import { useApi } from '../ApiContext';

moment.locale('es');

const ModalCalculaSaldos = ({  visible, onCancel, onCreate }) => {
  const { apiGET,apiPOST,apiPUT,apiDELETE } = useApi();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setLoading (false);
    }
  }, [visible, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {

        const mes = values.fechaCalculo ? values.fechaCalculo.format('MM') : null
        const anio = values.fechaCalculo ? values.fechaCalculo.format('YYYY') : null
        setLoading (true);
        form.resetFields();
        onCreate(anio,mes);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const labelForm = 'Calcular saldo';
  return (
    
    <Modal 
      title={labelForm} 
      visible={visible} 
      onOk={handleOk} 
      onCancel={onCancel} 
      footer={[
        <Button key="back" onClick={onCancel} disabled={loading}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk} disabled={loading}>
          Calcular
        </Button>,
      ]}
      forceRender>
      {!loading && ( 
      <Form form={form} layout="vertical" name="bolsaCreditosForm">
      <Space><br></br></Space> 
        <Form.Item name="fechaCalculo" label="Mes de cálculo" rules={[{ required: true, message: 'Por favor, seleccione el año y mes de cálculo de saldos.' }]}>
        <DatePicker  picker="month" />
        </Form.Item>  
      </Form>)}
      {loading && (
        <Space style={{ "margin": '20px 0' , width: '100%'}}>
          <Spin spinning={true}></Spin>
          Calculando...
          </Space>
      )}
    </Modal>
  );
};

export default ModalCalculaSaldos;
