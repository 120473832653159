// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCookie, setCookie, deleteCookie } from './lib/cookies';
import { getToken , setToken} from './lib/sesion';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Intenta recuperar el usuario desde la cookie cuando el componente se monta
  useEffect(() => {
    const verifyUserSession = async () => {
      const storedUserData = getCookie('authToken');
console.log('storedUserData',storedUserData)
      if (storedUserData) {
        try {
          //const userData = JSON.parse(decodeURIComponent(storedUserData));
          const userData = {token: decodeURIComponent(storedUserData)}
          setUser(userData);
          setToken(userData); // Asume que esta función maneja adecuadamente el userData
        } catch (error) {
          console.error("Error al parsear los datos del usuario almacenados:", error);
          // Opcional: Manejar el error, por ejemplo, limpiando los datos corruptos en cookies
          deleteCookie('authToken');
        }
      }
      setIsLoading(false); // Indica que la verificación ha terminado
      console.log('Active User', storedUserData);
    };

    verifyUserSession();
  }, []);

  const login = (userData) => {

    console.log('quien llama aqui??????')
    setUser(userData);
    setToken(userData);
    setCookie('authToken', encodeURIComponent(JSON.stringify(userData)));
    console.log(userData);
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    deleteCookie('authToken');
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
