import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popover, Breadcrumb, Button, Space, Input  } from 'antd';
import Title from 'antd/es/typography/Title';
import { InfoCircleOutlined,HomeOutlined,SearchOutlined ,CreditCardOutlined,CalculatorOutlined, BarChartOutlined } from '@ant-design/icons';
import { format,isValid, differenceInYears, differenceInMonths, differenceInDays  } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../ApiContext';
import ModalCalculaSaldos from './ModalCalculaSaldos'; 

const { Search } = Input;

function HomePage() {
  const navigate = useNavigate();
  const { apiGET,apiPOST,apiPUT,apiDELETE } = useApi();
  const [modalCalculaVisible, setModalCalculaVisible] = useState(false);

  const calculaSaldosCliente = async (anio, mes) => {
    console.log('Calcula saldos', anio, mes);

    try {
   
      const url = `/api/procesa/saldosafecha/${anio}/${mes}`;
      const datos = {};

      await apiPOST(url, datos)
        .then(data => {
          console.log(`Fin Calcula saldos - ${anio}/${mes}`,data)
          fetchResumenCreditos();
        })
        .catch(error => {
          console.error(`Error al calcular saldos - ${anio}/${mes}`, error)
          throw new Error(`Error al calcular saldos - ${anio}/${mes}`);
      });

    } catch (error) {
      console.error("Error al calcular saldos:", error);
    }
    setModalCalculaVisible(false)
  };

  function formatDateString(dateString) {

    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);
    if (isValid(date)) {
      // Formatea la fecha como prefieras. Aquí usamos el formato 'dd/MM/yyyy'.
      return format(date, 'dd/MM/yyyy');
    } else {
      // Maneja el caso de fecha inválida como prefieras
      return 'Fecha inválida';
    }
  }

  function calcularAnosYMesesDesde(fecha) {
    if (!fecha) {
      return null;
    }
  
    const fechaInicio = new Date(fecha);
    const fechaActual = new Date();
  
    const anos = differenceInYears(fechaActual, fechaInicio);
    const totalMeses = differenceInMonths(fechaActual, fechaInicio);
    const meses = totalMeses - (anos * 12);
    const dias = differenceInDays(fechaActual, fechaInicio);
  
    let resultado = '';
  
    // Determina si se debe usar el singular o plural para años y meses, y calcula los días si es necesario
    if (anos > 0) {
      const textoAnos = anos === 1 ? 'año' : 'años';
      resultado = `${anos} ${textoAnos}`;
    }
    
    if (meses > 0) {
      const textoMeses = meses === 1 ? 'mes' : 'meses';
      resultado += resultado ? ` y ${meses} ${textoMeses}` : `${meses} ${textoMeses}`;
    }
    
    if (anos === 0 && meses === 0) {
      // Si no hay diferencia en años ni meses, calcula la diferencia en días
      const textoDias = dias === 1 ? 'día' : 'días';
      resultado = `${dias} ${textoDias}`;
    }
  
    return resultado;
  }
 
  const [resumenCreditos, setResumenCreditos] = useState([]);

  async function fetchResumenCreditos () {
    try {
      var response = await apiGET('/api/clientes/creditos/resumen'); 

      setResumenCreditos (response || []);

    } catch (error) {
      console.error("Error al cargar resumen creditos:", error);
    }
  };

  useEffect(() => {

    fetchResumenCreditos()
    
    console.log("resumen",resumenCreditos)
  }, []); // El array vacío asegura que este efecto se ejecute solo una vez al montar el componente  
  
  function formatNegativeNumbers(numero , red=0, orange=1) {
    const isNegativeRed = numero < red;
    const isNegativeOrange = numero <= orange;
    let res;

    if (isNegativeRed) {
      res = (
        <span style={{ color: 'red' }}>
          {numero}
        </span>
      )
    } else {
      res = (
        <span style={{ color: isNegativeOrange ? 'orange': 'inherit' }}>
          {numero}
        </span>
      )
    }

    return res;
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id_cliente_sqpfirma',
      key: 'id_cliente_sqpfirma',
      width: 80,
      fixed: true,
    },
  /*  {
      title: 'LOGINAPI',
      dataIndex: 'LOGINAPI',
      render: (text) => <a>{text}</a>,
      key: 'LOGINAPI',
    },*/
    {
      title: 'Tenant',
      dataIndex: 'NOMBRE',
      key: 'NOMBRE',
      width: 310,
      fixed: true,
      ellipsis: true,
    },
    {
      title: 'Activación',
      dataIndex: 'fecha_activacion',
      key: 'fecha_activacion',
      render: (text) => formatDateString(text),
      width: 110,
      responsive: ['xxl','xl'],
    },
    {
      title: 'Antigüedad',
      dataIndex: 'fecha_activacion',
      key: 'fecha_activacion',
      render: (text) => calcularAnosYMesesDesde(text),
      width: 140,
      responsive: ['xxl','xl'],
    },
    /*{
      title: 'Caducidad',
      dataIndex: 'fecha_caducidad',
      key: 'fecha_caducidad',
      render: (text) => formatDateString(text),
      width: 90,
    },*/
    {
      title: (
        <div>
          BT
          <Popover title="BT" content="Bolsa de créditos Total. Suma de todas las bolsas.">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      dataIndex: 'bolsa_total',
      key: 'bolsa_total',
      width: 90,
      align: 'right'
    },
    {
      title: (
        <div>
          BA
          <Popover title="BA" content="Bolsa de créditos acutal">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      dataIndex: 'bolsa_actual',
      key: 'bolsa_actual',
      width: 90,
      align: 'right'
    },
    {
      title: (
        <div>
          FAA
          <Popover title="FAA" content="Fecha de activación de la bolsa actual (Última).">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      dataIndex: 'fecha_activacion_actual',
      key: 'fecha_activacion_actual',
      render: (text) => formatDateString(text),
      width: 110,

    },
    {
      title: (
        <div>
          FCA
          <Popover title="FCA" content="Fecha de caducidad de la bolsa actual (Última).">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      dataIndex: 'fecha_caducidad_actual',
      key: 'fecha_caducidad_actual',
      render: (text) => formatDateString(text),
      width: 110,

    },
    {
      title: (
        <div>
          CACM
          <Popover title="CACM" content="Consumo acumulado desde primera activación">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      dataIndex: 'consumo_actual',
      key: 'consumo_actual',
      _render: (text) => formatDateString(text),
      width: 100,
      align: 'right'
    },
    {
      title: (
        <div>
          CM6
          <Popover title="CM6" content="Consumo medio últimos 6 meses">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      dataIndex: 'MediaFirmasSignaturit',
      key: 'MediaFirmasSignaturit',
      render: (value, record) => {
        const number = parseFloat(value);
        if (!isNaN(number)) {
          return formatNegativeNumbers(parseFloat(number.toFixed(0)),1,10);
        }
        return formatNegativeNumbers(value || null,-1, record.MediaFirmasSignaturit);
      },
      width: 90,
      align: 'right'
    },
    {
      title: (
        <div>
          Saldo
          <Popover title="Saldo" content="Último saldo calculado por cliente">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      dataIndex: 'saldo_actual',
      key: 'saldo_actual',
      render: (value, record) => {
        const number = parseFloat(value);
        if (!isNaN(number)) {
          return formatNegativeNumbers(parseFloat(number.toFixed(2)), 1, record.MediaFirmasSignaturit);
        }
        return formatNegativeNumbers(value || null,-1, record.MediaFirmasSignaturit);
      },
      width: 90,
      align: 'right'
    },
    {
      title: (
        <div>
          FLSP
          <Popover title="Forecasted lifespan" content={(<div><div>Número de meses de saldo disponible en base</div><div>al consumo medio mensual (últimos 6 meses)</div> y saldo disponible.</div>)}>
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      dataIndex: 'forecast_meses',
      key: 'forecast_meses',
      render: (value) => {
        // Asegúrate de que el valor es un número antes de intentar redondearlo
        const number = parseFloat(value);
        // Verifica si el valor es un número válido para evitar mostrar NaN
        if (!isNaN(number)) {
          // Redondea al número a dos decimales y conviértelo de nuevo a número flotante
          return formatNegativeNumbers(parseFloat(number.toFixed(2)),0.01,1);
        }
        // Retorna el valor original o un placeholder en caso de valor no válido
        return formatNegativeNumbers(value || null,0.01,1);
      },
      width: 90,
      align: 'right'
    },    
    {
      title: 'Acciones',
      dataIndex: 'fecha_caducidad_actual',
      key: 'fecha_caducidad_actual',
      render: (text, record) => (
        <div style={{display:'inline-flex' , gap:'5px'}}>
          <Popover content="Gestionar bolsas de créditos">
            <Button  onClick={() => navigate(`/creditos/${record.id_cliente_sqpfirma}`)}><CreditCardOutlined /> Bolsas</Button>
          </Popover>
          {/* <Popover content="Consumos">
            <Button  onClick={() => navigate(`/creditos/${record.id_cliente_sqpfirma}`)}><BarChartOutlined /></Button>
          </Popover> */}
        </div>
      ),
      width: 110,
    },
  ];

  const [showPopover, setShowPopover] = useState(false);
  return (
    <div style={{ padding: '0',  display: 'flex', flexDirection: 'column' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Hire & Sign</Breadcrumb.Item>
        <Breadcrumb.Item>Clientes</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' , marginBottom:'10px'}}>
        <Title level={2} style={{ marginBottom: '3px', marginTop: 0, lineHeight: 'initial' }}>Clientes</Title>
      </div>
      <Space style={{ marginBottom: '16px' }} direction="horizontal">
        <Input placeholder="Buscar texto" prefix={<SearchOutlined />} style={{ width: 200 }} />
        <Button type="primary" onClick={() => {  }}>Nuevo cliente</Button>
        <Button type="" onClick={() => { setModalCalculaVisible(true); }}><CalculatorOutlined />Calcular saldo</Button>
      </Space>
      <div className="grid" style={{ height: '100%', maxHeight:'100%', overflow: 'hide' }}>
        <Table 
          bordered={true}
          rowKey="id_cliente_sqpfirma"
          pagination={false}
          scroll={{ y: 'calc(100vh - 260px)' }}
          columns={columns} 
          dataSource={resumenCreditos} 
        />
      </div>
      {modalCalculaVisible && (
        <ModalCalculaSaldos
          visible={modalCalculaVisible}
          onCreate={calculaSaldosCliente}
          onCancel={() => setModalCalculaVisible(false)}
        />
      )}
    </div>

  );


}

export default HomePage;

