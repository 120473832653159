import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import './App.css';
import { Layout, Menu } from 'antd';
import { DesktopOutlined,LogoutOutlined, UserOutlined } from '@ant-design/icons';

import { AuthProvider, useAuth } from './AuthContext';
import { ApiProvider } from './ApiContext';
import { LoginPage, LogoutButton } from './LoginPage';


// Importa tus componentes
import HomePage from './components/HomePage';
import TenantBolsas from './components/TenantBolsas';
import HSIcon from './hsIcon';
import Contafirma from './components/contafirmas';
import Sites from './components-sites/Sites';
import Users from './components-users/Users';

const { SubMenu } = Menu; 
const { Header, Content, Footer, Sider } = Layout;

const ProtectedLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { logout } = useAuth(); // Accede a logout a través del hook useAuth

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const handleLogout = () => {
    console.log('handlelogout')
    logout(); 
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>

            
            <div className={`logo ${collapsed ? 'collapsed' : ''}`}>
                <img src="/sqplogo.svg" alt="Logo" style={{ maxWidth: '100%', maxHeight: '32px' }} />
            </div>
       

            <Menu style={{ padding: '0px 10px' }} theme="dark" defaultSelectedKeys={['1']} mode="inline">
              <SubMenu key="sub1" icon={<HSIcon />} title="Hire & Sign">
                <Menu.Item key="3">
                  <Link to="/">Clientes</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/contafirma">Contafirmas</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="2" icon={<DesktopOutlined />}>
                <Link to="/sites">Entornos</Link>
              </Menu.Item>
              <Menu.Item key="6" icon={<UserOutlined />}>
                <Link to="/admin/users">Usuarios</Link>
              </Menu.Item>
              <Menu.Item className="logout-button-container" key="5" icon={<LogoutOutlined />} onClick={handleLogout}>
                Cerrar sesión
              </Menu.Item>
            </Menu>

      </Sider>
      <Layout className="site-layout" style={{ overflow: 'hidden' ,height: '100vh'}}>
        <Content style={{ margin: '0 20px' }}>
          <div style={{ padding: 8, minHeight: 360 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ display:'flex', alignItems:'center', justifyContent:'center', color: '#b1b1b1',margin: '0', padding:'0', height:'30px',  textAlign: 'center',fontSize: '12px' }}>Squarepoint ©2024</Footer>
      </Layout>
    </Layout>
  );
};

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useAuth();
  console.log({ isLoading, user }); // Agrega esto en `ProtectedRoute` para depurar

  if (isLoading) {
    // Aquí puedes retornar un indicador de carga si lo deseas
    return <div>Cargando...</div>;
  }
  
  if (!user) {
    console.log('No hay sesion');
    return <Navigate to="/login" />;
  }
  return <ProtectedLayout>{children}</ProtectedLayout>;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ApiProvider>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
            <Route path="/creditos/:id_cliente_sqpfirma" element={<ProtectedRoute><TenantBolsas /></ProtectedRoute>} />
            <Route path="/contafirma" element={<ProtectedRoute><Contafirma /></ProtectedRoute>} />
            <Route path="/sites" element={<ProtectedRoute><Sites /></ProtectedRoute>} />
            <Route path="/admin/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />

            {/* Agrega más rutas protegidas aquí */}
          </Routes>
        </ApiProvider>
      </Router>
    </AuthProvider>
  );
};

export default App;
