import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { useApi } from '../ApiContext';
import QuillEditor from '../common/QuillEditor';

const { Option } = Select;

const UserFormModal = ({ userId, accion, visible, onCancel, onCreate }) => {
  const [form] = Form.useForm();
  const isEditMode = Boolean(userId);
  const { apiGET } = useApi();
  const [user, setUser] = useState({});

  useEffect(() => {
    if (visible && userId) {
      const fetchUser = async () => {
        try {
          const data = await apiGET(`/api/usuarios/${userId}`);
          setUser(data[0] || {});
          form.setFieldsValue({
            email: data[0] ? data[0].email : null,
            n_usuario: data[0] ? data[0].n_usuario : null,
            tipo_usuario: data[0] ? data[0].tipo_usuario : null,
            avatar_url: data[0] ? data[0].avatar_url : null,
            estado_usuario: data[0] ? data[0].estado_usuario : null,
            posicion: data[0] ? data[0].posicion : null,
            movil: data[0] ? data[0].movil : null,
            telefono: data[0] ? data[0].telefono : null,
            firma: data[0] ? data[0].firma : null,
          });
        } catch (error) {
          console.error("Error al cargar el usuario:", error);
        }
      };

      fetchUser();
    } else if (visible && accion === 'new') {
      form.resetFields();
    }
  }, [visible, userId, accion, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(userId, values, accion);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title={isEditMode ? 'Modificar Usuario' : 'Añadir Nuevo Usuario'}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      width={1000}
      forceRender
    >
      <Form form={form} layout="vertical" style={{ margin: '30px 0' }}>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Por favor, ingrese el email del usuario.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="n_usuario"
          label="Nombre de Usuario"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="tipo_usuario"
          label="Tipo de Usuario"
          rules={[{ required: true, message: 'Por favor, ingrese el tipo de usuario.' }]}
        >
          <Select>
            <Option value="admin">Admin</Option>
            <Option value="user">User</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="avatar_url"
          label="Avatar URL"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="estado_usuario"
          label="Estado"
          rules={[{ required: true, message: 'Por favor, ingrese el estado del usuario.' }]}
        >
          <Select>
            <Option value="activo">Activo</Option>
            <Option value="desactivado">Desactivado</Option>
            <Option value="eliminado">Eliminado</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="posicion"
          label="Posición"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="movil"
          label="Móvil"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="telefono"
          label="Teléfono"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="firma"
          label="Firma"
        >
             <QuillEditor theme="snow" value={form.getFieldValue('firma')} onChange={(value) => form.setFieldsValue({ firma: value })} />

         {/*  <Input.TextArea /> */}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserFormModal;
