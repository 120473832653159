import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format, isValid } from 'date-fns';
import { Table, Breadcrumb, Input, Space, Button, Modal, Popover, Descriptions ,Affix, Card} from 'antd';
import { HomeOutlined, InfoCircleOutlined, ArrowLeftOutlined, CalculatorOutlined, PlusOutlined, MailOutlined, DeleteOutlined } from '@ant-design/icons';
import ModalBolsaCreditos from './ModalBolsaCreditos';
import ModalCalculaSaldos from './ModalCalculaSaldos';
import ModalEnviaEmail from './ModalEnviaEmail';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import { formatDateString, formatNegativeNumbers } from '../lib/utils';
import { useApi } from '../ApiContext';
import CreditUsageComponent from '../common/CreditUsageComponent';
import ConsumoBarChart from '../common/ConsumoBarChart';
import './TenantBolsas.css';

const { Search } = Input;

function TenantBolsas() {
  const { apiGET, apiPOST, apiPUT, apiDELETE } = useApi();
  const [creditosCliente, setCreditosCliente] = useState([]);
  const [cliente, setCliente] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalCalculaVisible, setModalCalculaVisible] = useState(false);
  const [modalEnviaEmailVisible, setModalEnviaEmailVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [resumenCreditos, setResumenCreditos] = useState(null);
  const [ultimosConsumos, setUltimosConsumos] = useState([]);
  const [pronosticoData, setPronosticoData] = useState(null);
  const { id_cliente_sqpfirma } = useParams();

  useEffect(() => {
    fetchCreditosCliente();
    fetchResumenCreditos();
  }, [id_cliente_sqpfirma]);

  const fetchCreditosCliente = async () => {
    try {
      const response = await apiGET(`/api/clientes/cliente/${id_cliente_sqpfirma}/creditos`);

      setCreditosCliente(response?.creditos || []);
      setCliente(response?.cliente[0] || {});

      console.log('data.cliente', cliente, response);
    } catch (error) {
      console.error("Error al cargar resumen creditos:", error);
    }
  };

  const fetchResumenCreditos = async () => {
    try {
      var resumen = await apiGET(`/api/clientes/creditos/resumen/${id_cliente_sqpfirma}`);
      var consumos = await apiGET(`/api/clientes/cliente/${id_cliente_sqpfirma}/ultimosconsumos`);


      let pronosticoData = {
        consumos: consumos,
        bolsa_actual: resumen[0].bolsa_actual,
        fecha_inicio: resumen[0].fecha_activacion_actual,
        fecha_fin: resumen[0].fecha_caducidad_actual,
        consumo_medio: resumen[0].MediaFirmasSignaturit
      };

      setPronosticoData(pronosticoData || {});
      setResumenCreditos(resumen[0] || {});
      setUltimosConsumos(consumos || {});
    } catch (error) {
      console.error("Error al cargar resumen creditos:", error);
    }
  };

  const calculaSaldosCliente = async (anio, mes) => {
    console.log('Calcula saldos', anio, mes);

    try {
      const url = `/api/procesa/saldos/${id_cliente_sqpfirma}/${anio}/${mes}`;
      const datos = {};

      await apiPOST(url, datos)
        .then(data => {
          console.log(`Fin Calcula saldos cliente:${id_cliente_sqpfirma} - ${anio}/${mes}`, data);
          fetchResumenCreditos();
          fetchCreditosCliente();
        })
        .catch(error => {
          console.error(`Error al calcular saldos cliente:${id_cliente_sqpfirma} - ${anio}/${mes}`, error);
          throw new Error(`Error al calcular saldos cliente:${id_cliente_sqpfirma} - ${anio}/${mes}`);
        });
    } catch (error) {
      console.error("Error al calcular saldos cliente:", error);
    }
    setModalCalculaVisible(false);
  };

  const handleCreateOrUpdate = async (id_bolsa, values, accion) => {
    console.log('Received values of form: ', values, accion);

    if (accion === 'new') {
      await handleCreate(values);
    } else {
      await handleUpdate(id_bolsa, values);
    }
    console.log('done!', accion);
    setModalVisible(false);
    fetchCreditosCliente();
  };

  const handleCreate = async (formValues) => {
    try {
      const response = await apiPOST(`/api/clientes/cliente/${id_cliente_sqpfirma}/creditos`, formValues);
      console.log(response);
      setModalVisible(false);
    } catch (error) {
      console.error("Error al crear la bolsa:", error);
    }
  };

  const handleUpdate = async (id_creditos, formValues) => {
    try {
      const response = await apiPUT(`/api/clientes/cliente/${id_cliente_sqpfirma}/creditos/${id_creditos}`, formValues);
      console.log(response);
      setModalVisible(false);
    } catch (error) {
      console.error("Error al actualizar la bolsa:", error);
    }
  };

  const handleDelete = async (id_creditos) => {
    try {
      const response = await apiDELETE(`/api/clientes/cliente/${id_cliente_sqpfirma}/creditos/${id_creditos}`);
      console.log("Bolsa eliminada con éxito", response);
      fetchCreditosCliente();
    } catch (error) {
      console.error("Error al eliminar la bolsa:", error);
    }
  };

  const showDeleteConfirm = (id_creditos) => {
    Modal.confirm({
      title: '¿Estás seguro de que quieres borrar esta bolsa de créditos?',
      content: 'Esta acción no se puede deshacer',
      okText: 'Sí, borrar',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id_creditos);
      },
      onCancel() {
        console.log('Cancelado');
      },
    });
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id_creditos_firma',
      key: 'id_creditos_firma',
      width: 80,
    },
    {
      title: 'Activación',
      dataIndex: 'fecha_activacion',
      key: 'fecha_activacion',
      render: (text) => formatDateString(text),
      width: 110,
    },
    {
      title: 'Caducidad',
      dataIndex: 'fecha_caducidad',
      key: 'fecha_caducidad',
      render: (text) => formatDateString(text),
      width: 180,
    },
    {
      title: (
        <div>
          CA
          <Popover content="Número de reditos asignados a la bolsa.">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      dataIndex: 'creditos_asignados',
      key: 'creditos_asignados',
      width: 110,
    },
    {
      title: 'Notas',
      dataIndex: 'notas',
      key: 'notas',
    },
    {
      title: 'Acción',
      key: 'accion',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => { setModalVisible(true); setCurrentRecord(record); }}>Editar</Button>
          <Button onClick={() => { setModalEnviaEmailVisible(true); setCurrentRecord(record); }}><MailOutlined /></Button>
          <Button danger onClick={() => { showDeleteConfirm(record.id_creditos_firma); }}><DeleteOutlined/></Button>
        </Space>
      ),
    },
  ];

  const detalle = [
    {
      key: '1',
      label: 'E-mail',
      children: resumenCreditos?.email_signaturit,
      span:2
    },
    {
      key: '1',
      label: '',
      children: '',
      span:2
    },
    {
      key: '1',
      label: 'Activación',
      children: formatDateString(resumenCreditos?.fecha_activacion),
    },
    {
      key: '1',
      label: 'Caducidad',
      children: formatDateString(resumenCreditos?.fecha_caducidad_actual),
    },
    {
      key: '1',
      label: (
        <div>
          BA
          <Popover content="Bolsa actual de créditos">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      children: resumenCreditos?.bolsa_actual,
    },
    {
      key: '1',
      label: 'Saldo',
      children: formatNegativeNumbers(resumenCreditos?.saldo_actual, 1, resumenCreditos?.MediaFirmasSignaturit),
    },
    {
      key: '1',
      label: '',
      children: '',
      span: 2
    },
    {
      key: '1',
      label: (
        <div>
          BT
          <Popover content="Bolsa total de créditos">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      children: resumenCreditos?.bolsa_total,
    },
    {
      key: '1',
      label: (
        <div>
          CACM
          <Popover content="Consumo acumulado desde primera activación">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      children: resumenCreditos?.consumo_actual,
    },
    {
      key: '1',
      label: (
        <div>
          CM6
          <Popover content="Consumo médio últimos 6 meses">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      children: formatNegativeNumbers(resumenCreditos?.MediaFirmasSignaturit, 1, 10),
    },

    {
      key: '1',
      label: (
        <div>
          FLSP
          <Popover content="Forecasted lifespan">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </div>
      ),
      children: formatNegativeNumbers(resumenCreditos?.forecast_meses, 0.01, 1),
    },
  ];

  return (
    <div className="tenant-bolsas-container">
      <Affix offsetTop={0}>
      <div className="header" >
      <div style={{  display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
        <span  style={{  flex:'1',  display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Button
              icon={<ArrowLeftOutlined />}
              shape="circle"
              onClick={() => window.history.back()}
            />
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Hire & Sign</Breadcrumb.Item>
            <Breadcrumb.Item>Clientes</Breadcrumb.Item>
            <Breadcrumb.Item>{cliente.NOMBRE}</Breadcrumb.Item>
          </Breadcrumb>
        </span>
        <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Button type="primary" onClick={() => { setModalVisible(true); setCurrentRecord(null); }}><PlusOutlined />Nueva Bolsa</Button>
          <Button onClick={() => { setModalCalculaVisible(true); setCurrentRecord(null); }}><CalculatorOutlined />Calcular saldo</Button>
        </span>
        </div>
        <div style={{  display: 'none', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
          <Button
            icon={<ArrowLeftOutlined />}
            shape="circle"
            onClick={() => window.history.back()}
          />
          <Title level={2} style={{ marginBottom: '3px', marginTop: 0, lineHeight: 'initial' }}>{cliente.NOMBRE}</Title>
        </div>
        <Space style={{ display: 'none', marginBottom: '16px', width: '100%' }}>
          <Descriptions  style={{ display: 'none', marginBottom: '16px', width: '600px' }}
            column={2}
            bordered={false}
            size="small"
            items={detalle}
            layout="horizontal"
          />
        </Space>
        <Space style={{ display: 'none', marginBottom: '16px' }} direction="horizontal">
          <Search placeholder="Buscar texto" style={{ display: 'none', width: 200 }} />

          <Button type="primary" onClick={() => { setModalVisible(true); setCurrentRecord(null); }}><PlusOutlined />Nueva Bolsa</Button>
          <Button onClick={() => { setModalCalculaVisible(true); setCurrentRecord(null); }}><CalculatorOutlined />Calcular saldo</Button>
        </Space>
      </div>
      </Affix>
      <div className="content">
        <div className="charts">
          <Card title="Detalles" style={{ minWidth: '380px' ,overflowX:'auto', overflowY:'hidden'}}>

          <Descriptions  style={{ _marginBottom: '16px', minWidth: '380px' }}
                  column={2}
                  bordered={false}
                  size="small"
                  items={detalle}
                  layout="horizontal"
                />

          </Card>
          <CreditUsageComponent pronosticoData={pronosticoData}/>
          <ConsumoBarChart data={ultimosConsumos} />


        </div>
        <Table
          bordered
          rowKey="id_creditos_firma"
          pagination={false}
          _scroll={{ y: 'calc(100vh - 221px)' }}
          columns={columns}
          dataSource={creditosCliente}
        />
      </div>
      {modalVisible && (
        <ModalBolsaCreditos
          visible={modalVisible}
          onCreate={handleCreateOrUpdate}
          onCancel={() => setModalVisible(false)}
          accion={currentRecord ? 'edit' : 'new'}
          id_cliente={currentRecord ? currentRecord.id_cliente_sqpfirma : id_cliente_sqpfirma}
          id_bolsa={currentRecord ? currentRecord.id_creditos_firma : null}
        />
      )}
      {modalCalculaVisible && (
        <ModalCalculaSaldos
          visible={modalCalculaVisible}
          onCreate={calculaSaldosCliente}
          onCancel={() => setModalCalculaVisible(false)}
        />
      )}
      {modalEnviaEmailVisible && (
        <ModalEnviaEmail
          visible={modalEnviaEmailVisible}
          id_cliente={currentRecord ? currentRecord.id_cliente_sqpfirma : id_cliente_sqpfirma}
          id_bolsa={currentRecord ? currentRecord.id_creditos_firma : null}
          onCreate={() => setModalEnviaEmailVisible(false)}
        />
      )}
    </div>
  );
}

export default TenantBolsas;
