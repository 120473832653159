import React, { useState, useEffect } from 'react';
import { Card, Popover, Button, Tooltip, Modal, Col, Breadcrumb, Space, Input, Tag, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { HomeOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import UserCard from './UserCard';
import UserFormModal from './UserFormModal';
import { useApi } from '../ApiContext';

function Users() {
  const [users, setUsers] = useState([]);
  const { apiGET, apiPOST, apiPUT, apiDELETE } = useApi();
  const [modalUserVisible, setModalUserVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const fetchUsers = async () => {
    try {
      const response = await apiGET('/api/usuarios');
      setUsers(response);
    } catch (error) {
      console.error("Error al cargar los usuarios:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDelete = (userId) => {
    Modal.confirm({
      title: '¿Realmente deseas eliminar este usuario?',
      onOk: async () => {
        await apiDELETE(`/api/usuarios/${userId}`);
        fetchUsers();
      },
    });
  };

  const handleCreateOrUpdate = async (id_usuario, values, accion) => {
    if (accion === 'new') {
      await handleCreate(values);
    } else {
      await handleUpdate(id_usuario, values);
    }
    setModalUserVisible(false);
    fetchUsers();
  };

  const handleCreate = async (formValues) => {
    try {
      const response = await apiPOST(`/api/usuarios`, formValues);
      setModalUserVisible(false);
    } catch (error) {
      console.error("Error al crear el usuario:", error);
    }
  };

  const handleUpdate = async (id_usuario, formValues) => {
    try {
      const response = await apiPUT(`/api/usuarios/${id_usuario}`, formValues);
      setModalUserVisible(false);
    } catch (error) {
      console.error("Error al actualizar el usuario:", error);
    }
  };

  return (
    <div style={{ padding: '0', display: 'flex', flexDirection: 'column', height: 'calc(100vh - 35px)' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Usuarios</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
        <Title level={2} style={{ marginBottom: '3px', marginTop: 0, lineHeight: 'initial' }}>Usuarios</Title>
      </div>
      <Space style={{ marginBottom: '16px' }} direction="horizontal">
        <Input placeholder="Buscar usuario" prefix={<SearchOutlined />} style={{ width: 200 }} />
        <Button type="primary" onClick={() => { setModalUserVisible(true); setCurrentRecord(null); }}><PlusOutlined />Nuevo usuario</Button>
      </Space>
      <div className="grid" style={{ flexGrow: 1, overflow: 'hidden' }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '15px',
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          justifyContent: 'flex-start',
          overflowY: 'auto',
          height: '100%',
        }}>
          {users.map((user) => (
            <UserCard key={user.id_usuario} user={user} handleDelete={handleDelete} handleUpdate={() => { setModalUserVisible(true); setCurrentRecord(user); }} />
          ))}
        </div>
      </div>

      {modalUserVisible && (
        <UserFormModal
          visible={modalUserVisible}
          onCreate={handleCreateOrUpdate}
          onCancel={() => setModalUserVisible(false)}
          accion={currentRecord ? 'edit' : 'new'}
          userId={currentRecord ? currentRecord.id_usuario : null}
        />
      )}
    </div>
  );
}

export default Users;
