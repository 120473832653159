import React, { useState, useEffect } from 'react';

import {  Breadcrumb } from 'antd';
import Title from 'antd/es/typography/Title';
import { HomeOutlined } from '@ant-design/icons';



function Contafirmas() {


  useEffect(() => {
    console.log("contafirmas")
  }, []); 
  
  const LookerStudioReport = () => {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <iframe 
          title="looker"
          width="1250px" 
          height="1900px" 
          src="https://lookerstudio.google.com/embed/reporting/87cfe971-e9e4-46b6-bb2a-66e155ec3e15/page/p_f74c282o7c" 
          frameBorder="0" 
          style={{ border: 0 }}
          allowFullScreen 
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
        </iframe>
      </div>
    );
  };
  
  return (
    <div style={{ padding: '0', display: 'flex', flexDirection: 'column', height: 'calc(100vh - 35px)' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Hire & Sign</Breadcrumb.Item>
        <Breadcrumb.Item>Contafirma</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' , marginBottom:'10px'}}>
        <Title level={2} style={{ marginBottom: '3px', marginTop: 0, lineHeight: 'initial' }}>Contafirma</Title>
      </div>


      <div className="grid" style={{ flexGrow: 1, overflow: 'hidden' }}> 
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '15px',
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          justifyContent: 'flex-start',
          overflowY: 'auto', // Esto permite el desplazamiento vertical
          height: '100%', // Asegúrate de que este div también ocupe el alto disponible del contenedor padre
        }}>
          <LookerStudioReport />
        </div>
      </div>
      
     
    </div>

  );


}

export default Contafirmas;

