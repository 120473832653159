// ApiContext.js
import React, { createContext, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken , setToken} from './lib/sesion';

const ApiContext = createContext();

export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ children }) => {
    const navigate = useNavigate();

    const apiGET = useCallback(async (url, options = {}) => {

        try {
            const token = getToken().token; // Asegúrate de que esta función maneje correctamente cuando no hay token
            const headers = new Headers(options.headers || {});
            headers.append('Authorization', `Bearer ${token}`);
            headers.append('Content-Type', `application/json`);

            const response = await fetch(url, { method: 'GET', headers, ...options });
            if (response.status === 401 || response.status === 403) {
                console.log('Error 403:' + response.data );
                navigate('/login');
                throw new Error('La solicitud falló con estado ' + response.status);
            }
            if (response.status === 430) {
                let {url} = await response.json();
                console.log('Redirect url:', url );
                window.location.href = url;
                throw new Error('Token no válido:');
            }   
            if (!response.ok) {
                throw new Error('La solicitud falló con estado ' + response.status);
            }
            return await response.json();
        } catch (error) {
            console.error('Error en apiGET:', error);
            throw error; // O maneja este error de manera que prefieras
        }
    }, [navigate]);

    const apiPOST = useCallback(async (url, datos, options = {}) => {
        const token = getToken().token;
        console.log('post', token)
        const headers = new Headers(options.headers || {});
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', `application/json`);
    
    
        try {
            const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(datos),
            });

            if (response.status === 401 || response.status === 403) {
                console.log('Error 403:' + response.data );
                navigate('/login');
                throw new Error('La solicitud falló con estado ' + response.status);
            }
            if (response.status === 302) {
                console.log('Redirect 302:' + response.data );
                window.location.href = response.data.url;
                throw new Error('Token no válido:' + response.status);
            } 
            // Intenta leer el cuerpo de la respuesta como JSON, si falla, intenta como texto plano
            let responseBody;
            try {
                responseBody = await response.json();
            } catch (error) {
                responseBody = await response.text();
            }
        
            if (!response.ok) {
                // Construye un mensaje de error basado en el cuerpo de la respuesta
                const errorMessage = typeof responseBody === 'object' ? responseBody.message : responseBody;
                const errorDetail = errorMessage || `Error desconocido con estado: ${response.status}`;
                throw new Error(`Error en la solicitud a ${url}: ${errorDetail}`);
            }
        
            return responseBody; // Retorna el cuerpo de la respuesta para respuestas exitosas

        } catch (error) {
          console.error('Hubo un problema con la solicitud fetch:', error.message);
          // Aquí puedes decidir si quieres re-lanzar el error o manejarlo de alguna manera
          throw error;
        }
    }, [navigate]);
    
      
    const apiPUT = useCallback(async (url, datos, options = {}) => {
        const token = getToken().token;
    
        const headers = new Headers(options.headers || {});
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', `application/json`);
    
    
        try {
            const response = await fetch(url, {
            method: 'PUT',
            headers,
            body: JSON.stringify(datos),
            });

            if (response.status === 401 || response.status === 403) {
                console.log('Error 403:' + response.data );
                navigate('/login');
                throw new Error('La solicitud falló con estado ' + response.status);
            }
            if (response.status === 302) {
                console.log('Redirect 302:' + response.data );
                window.location.href = response.data.url;
                throw new Error('Token no válido:' + response.status);
            } 
            // Intenta leer el cuerpo de la respuesta como JSON, si falla, intenta como texto plano
            let responseBody;
            try {
            responseBody = await response.json();
            } catch (error) {
            responseBody = await response.text();
            }
    
            if (!response.ok) {
            // Construye un mensaje de error basado en el cuerpo de la respuesta
            const errorMessage = typeof responseBody === 'object' ? responseBody.message : responseBody;
            const errorDetail = errorMessage || `Error desconocido con estado: ${response.status}`;
            throw new Error(`Error en la solicitud a ${url}: ${errorDetail}`);
            }
    
            return responseBody; // Retorna el cuerpo de la respuesta para respuestas exitosas
        } catch (error) {
            console.error('Hubo un problema con la solicitud fetch:', error.message);
            // Aquí puedes decidir si quieres re-lanzar el error o manejarlo de alguna manera
            throw error;
        }
    }, [navigate]);

    
    const apiDELETE = useCallback(async (url, datos, options = {}) => {
        const token = getToken().token;
    
        const headers = new Headers(options.headers || {});
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', `application/json`);
      
        try {
            const response = await fetch(url, {
            method: 'DELETE',
            headers,
            body: JSON.stringify(datos),
            });

            if (response.status === 401 || response.status === 403) {
                console.log('Error 403:' + response.data );
                navigate('/login');
                throw new Error('La solicitud falló con estado ' + response.status);
            }
            if (response.status === 302) {
                console.log('Redirect 302:' + response.data );
                window.location.href = response.data.url;
                throw new Error('Token no válido:' + response.status);
            } 
            // Intenta leer el cuerpo de la respuesta como JSON, si falla, intenta como texto plano
            let responseBody;
            try {
            responseBody = await response.json();
            } catch (error) {
            responseBody = await response.text();
            }
    
            if (!response.ok) {
            // Construye un mensaje de error basado en el cuerpo de la respuesta
            const errorMessage = typeof responseBody === 'object' ? responseBody.message : responseBody;
            const errorDetail = errorMessage || `Error desconocido con estado: ${response.status}`;
            throw new Error(`Error en la solicitud a ${url}: ${errorDetail}`);
            }
    
            return responseBody; // Retorna el cuerpo de la respuesta para respuestas exitosas
        } catch (error) {
            console.error('Hubo un problema con la solicitud fetch:', error.message);
            // Aquí puedes decidir si quieres re-lanzar el error o manejarlo de alguna manera
            throw error;
        }
    }, [navigate]);

    return (
        <ApiContext.Provider value={{ apiGET,apiPOST,apiPUT,apiDELETE }}>
            {children}
        </ApiContext.Provider>
    );
};
