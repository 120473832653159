import React, { useState, useEffect } from 'react';
import { Card, Popover, Button, Tooltip, Modal, Col,Breadcrumb,Space,Input, Tag,Row} from 'antd';
import Title from 'antd/es/typography/Title';
import { LinkOutlined, EditOutlined, DeleteOutlined, InfoCircleOutlined,HomeOutlined ,SearchOutlined, PlusOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { useApi } from '../ApiContext';
import ModalSite from './ModalSites'; 

const WebsiteCard = ({ website, handleDelete, handleUpdate }) => {
    

  const formatDate = (dateString) => {
    return dateString ? moment(dateString).format('L LTS') : 'N/A';
  };

  const content = (
    <div>
      <p><b>Creación:</b> {formatDate(website.fecha_creacion)}</p>
      <p><b>Modificación:</b> {formatDate(website.fecha_modificacion)}</p>
      <p><b>Notas:</b> <div dangerouslySetInnerHTML={{ __html: website.notas }} /></p>
    </div>
  );

  return (
    <div style={{ margin: '0px', width: '23%', 'min-width':'290px' }}>
        <Card
        title={website.nombre}
        bordered={false}
        actions={[
            <EditOutlined key="delete" onClick={() => handleUpdate(website.id)} />,
            <DeleteOutlined key="delete" onClick={() => handleDelete(website.id)} />,
            <Popover content={content} title="Información" trigger="hover">
            <InfoCircleOutlined key="info" />
            </Popover>,
        ]}
        >

        <div>
            <a href={website.link} target="_blank" rel="noopener noreferrer">
                <LinkOutlined /> {website.link}
            </a>
        </div>

        <div style={{ margin: '15px 0 0 0' }}>
            <Tag color="blue">{website.grupo}</Tag>
            <Tag color="green">{website.entorno}</Tag>
        </div>

      </Card>
    </div>
  );
};

function Sites() {
    const [websites, setWebsites] = useState([]);
    const { apiGET,apiPOST,apiPUT,apiDELETE } = useApi();
    const [modalSiteVisible, setModalSiteVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);

    const fetchWebsites = async () => {
        try {
        const response = await apiGET('/api/websites');
        setWebsites(response);
        } catch (error) {
        console.error("Error al cargar los websites:", error);
        }
    };

  useEffect(() => {
    fetchWebsites();
  }, []);

  const handleDelete = (siteId) => {
    Modal.confirm({
      title: '¿Realmente deseas eliminar este Entorno?',
      onOk: async () => {
        await apiDELETE(`/api/websites/${siteId}`);
        fetchWebsites();
      },
    });
  };

  const handleCreateOrUpdate = async (id_sitio, values, accion) => {
    console.log('Received values of form: ', values, accion);

    if (accion === 'new') {
      await handleCreate(values);
    } else {
      await handleUpdate(id_sitio, values);
    }
    console.log('done!', accion)
    setModalSiteVisible(false);

    fetchWebsites();
  };

  const handleCreate = async (formValues) => {
    try {
      const response = await apiPOST(`/api/websites/` , formValues);
      console.log(response); // "Bolsa creada con éxito"
      setModalSiteVisible(false);
      // Recargar los datos de la tabla aquí
    } catch (error) {
      console.error("Error al crear la bolsa:", error);
    }
  };

  const handleUpdate = async (id_sitio, formValues) => {
    try {
      const response = await apiPUT(`/api/websites/${id_sitio}`, formValues);
      console.log(response); // "Bolsa actualizada con éxito"
      setModalSiteVisible(false);
      // Recargar los datos de la tabla aquí
    } catch (error) {
      console.error("Error al actualizar la bolsa:", error);
    }
  };



  return (
    <div style={{ padding: '0', display: 'flex', flexDirection: 'column', height: 'calc(100vh - 35px)' }}> {/* Asegúrate de que este div ocupe todo el alto de la ventana */}
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Entornos</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
        <Title level={2} style={{ marginBottom: '3px', marginTop: 0, lineHeight: 'initial' }}>Entornos</Title>
      </div>
      <Space style={{ marginBottom: '16px' }} direction="horizontal">
        <Input placeholder="Buscar texto" prefix={<SearchOutlined />} style={{ width: 200 }} />
        <Button type="primary" onClick={() => { setModalSiteVisible(true); setCurrentRecord(null); }}><PlusOutlined />Nuevo entorno</Button>
      </Space>
      {/* Este div ahora tiene flex-grow para ocupar el espacio disponible */}
      <div className="grid" style={{ flexGrow: 1, overflow: 'hidden' }}> 
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '15px',
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          justifyContent: 'flex-start',
          overflowY: 'auto', // Esto permite el desplazamiento vertical
          height: '100%', // Asegúrate de que este div también ocupe el alto disponible del contenedor padre
        }}>
          {websites.map((website) => (
            <WebsiteCard key={website.id} website={website} handleDelete={handleDelete} handleUpdate={() => { setModalSiteVisible(true); setCurrentRecord(website); }} />
          ))}
        </div>
      </div>
  
      {modalSiteVisible && (
        <ModalSite
          visible={modalSiteVisible}
          onCreate={handleCreateOrUpdate}
          onCancel={() => setModalSiteVisible(false)}
          accion={currentRecord ? 'edit' : 'new'}
          siteId={currentRecord ? currentRecord.id : null}
        />
      )}
    </div>
  );
  
}

export default Sites;
