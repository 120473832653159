import React, { useState, useEffect  } from 'react';
import { DatePicker, Input, InputNumber, Card, Row, Col } from 'antd';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, Legend
} from 'recharts';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const CreditUsageComponent = ({ pronosticoData }) => {
  const [dateRange, setDateRange] = useState([]);
  const [creditPool, setCreditPool] = useState(0);
  const [monthlyUsage, setMonthlyUsage] = useState(0);
  const [monthlyConsumptions, setMonthlyConsumptions] = useState([]);

  const onDateChange = (dates) => {
    setDateRange(dates);
  };

  const calculateData = () => {
    if (dateRange.length === 0 || !monthlyUsage || !creditPool) return [];

    const start = dayjs(dateRange[0]);
    const end = dayjs(dateRange[1]);
    const totalMonths = end.diff(start, 'month') + 1;
    const months = [];
    let currentMonth = start;
    let remainingCredits = creditPool;

    for (let i = 0; i < totalMonths; i++) {
      const month = currentMonth.format('YYYY-MM');
      const realConsumption = monthlyConsumptions[i] || 0;
      if (i < monthlyConsumptions.length) {
        remainingCredits -= realConsumption;
        months.push({
          month,
          realCredits: Math.round(remainingCredits),
          forecast: null,
        });
      } else {
        remainingCredits -= monthlyUsage;
        months.push({
          month,
          realCredits: Math.round(remainingCredits),
          forecast: Math.round(remainingCredits),
          forecastColor: remainingCredits < 0 ? 'red' : 'grey',
        });
      }
      currentMonth = currentMonth.add(1, 'month');
    }

    return months;
  };

  const calculaMonthlyConsumptions = (data, fechaInicio, fechaFin) => {
    // Convertir las fechas a objetos Date
    const inicio = new Date(fechaInicio);
    const fin = new Date(fechaFin);

    // Filtrar los datos dentro del rango de fechas
    const resultado = data.filter(item => {
        const fechaItem = new Date(item.anio, item.mes - 1); 
        return fechaItem >= inicio && fechaItem <= fin;
    }).map(item => item.firmas_signaturit);

    console.log ("RES",resultado, inicio, fin, data)
    return resultado;
  }

  useEffect(() => {
    if (pronosticoData) {
      setDateRange([dayjs(pronosticoData.fecha_inicio), dayjs(pronosticoData.fecha_fin)]);
      setCreditPool(pronosticoData.bolsa_actual || 0);
      setMonthlyUsage(pronosticoData.consumo_medio || 0);
      setMonthlyConsumptions(pronosticoData.consumos ? calculaMonthlyConsumptions(pronosticoData.consumos, pronosticoData.fecha_inicio, pronosticoData.fecha_fin) : []);
    }
  }, [pronosticoData]);

    
  console.log("DATA_CONS_PRO",pronosticoData,dateRange,creditPool,monthlyUsage,monthlyConsumptions);

  const data = calculateData();
  //const maxCredits = Math.max(creditPool, ...data.map(d => d.realCredits !== null ? d.realCredits : d.forecast));

  return (
    <Card title="Evolución Saldo Créditos de Firma + Prónostico">
      <Row gutter={16} style={{ display: 'none' }}>
        <Col span={8}>
          <RangePicker onChange={onDateChange} picker="month" />
        </Col>
        <Col span={8}>
          <InputNumber
            placeholder="Credit Pool"
            style={{ width: '100%' }}
            onChange={(value) => setCreditPool(Number(value))}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            placeholder="Monthly Credit Usage"
            style={{ width: '100%' }}
            onChange={(value) => setMonthlyUsage(Number(value))}
          />
        </Col>
        <Col span={24} style={{ marginTop: '10px' }}>
          <Input
            placeholder="Enter monthly consumptions separated by commas"
            style={{ width: '100%' }}
            onChange={(e) => setMonthlyConsumptions(e.target.value.split(',').map(Number))}
          />
        </Col>
      </Row>
      
      <Row style={{ marginTop: 'none' }}>
        <ResponsiveContainer _width="100%" height={180}>
          <AreaChart
            data={data}
            margin={{ top: 15, right: 20, left: -20, bottom: 2 }}
          >
            <defs>
              <linearGradient id="colorReal" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorForecast" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#1677ff" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#1677ff" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month"  style={{ fontSize: '9px' }}/>
            <YAxis  style={{ fontSize: '9px' }} />
            <Tooltip contentStyle={{ fontSize: '10px' }}/> 
            <Legend wrapperStyle={{ fontSize: '10px' }}/>
            <Area
              type="monotone"
              dataKey="realCredits"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#colorReal)"
              name="Saldo créditos"
            >
              <LabelList dataKey="realCredits" position="top"  style={{ fontSize: '9px' }} />
            </Area>
            <Area
              type="monotone"
              dataKey="forecast"
              stroke="#1677ff"
              fillOpacity={1}
              fill="url(#colorForecast)"
              name="Pronóstico"
              strokeDasharray="5 5"
            >
              <LabelList dataKey="forecast" position="top"  style={{ fontSize: '9px' }} />
            </Area>
          </AreaChart>
        </ResponsiveContainer>
      </Row>
    </Card>
  );
};

export default CreditUsageComponent;
