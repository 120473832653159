import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Extiende Quill para soportar tablas y divs
const BlockEmbed = Quill.import('blots/block/embed');
class Table extends BlockEmbed {
  static create(value) {
    let node = super.create(value);
    node.innerHTML = value;
    return node;
  }

  static value(node) {
    return node.innerHTML;
  }
}
Table.blotName = 'table';
Table.tagName = 'table';

Quill.register(Table, true);

// Configuración del editor
const quillModules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    [{ align: [] }],
    ['clean'], // Botón para limpiar el formato
  ],
};

const QuillEditor = ({ value, onChange }) => {
  return (
    <ReactQuill
      theme="snow"
      modules={quillModules}
      value={value}
      onChange={(content, delta, source, editor) => {
        onChange(editor.getHTML());
      }}
    />
  );
};

export default QuillEditor;
