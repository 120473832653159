import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Space, Spin, Radio, notification} from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import { useApi } from '../ApiContext';
import ReactQuill, { Quill } from 'react-quill';
import dayjs from 'dayjs';
import 'react-quill/dist/quill.snow.css';
import ModalPlantillaEmail from './ModalPlantillaEmail';
import { EditOutlined } from '@ant-design/icons';
import QuillEditor from '../common/QuillEditor';
import {  } from 'antd';
const Mustache = require('mustache');

const { TextArea } = Input;

moment.locale('es');

// Función para sustituir las variables en la plantilla
const parseTemplate = (template, data) => {
  if (template) {
    return Mustache.render(template, data);
  } else {
    return '';
  }
};

const ModalEnviaEmail = ({ visible, onCreate, id_cliente, id_bolsa }) => {
  const { apiGET, apiPOST } = useApi();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Cargando...');
  const [plantillasEmail, setPlantillasEmail] = useState({});
  const [Usuario, setUsuario] = useState({});
  const [dataBolsa, setDataBolsa] = useState({});
  const [allPlantillasEmail, setAllPlantillasEmail] = useState([]);
  const [defaultPlantilla, setDefaultPlantilla] = useState(0);
  const [modalEnviaEmailVisible, setModalEnviaEmailVisible] = useState(false);

  useEffect(() => {
    if (dataBolsa && Object.keys(dataBolsa).length > 0) {
      refrescaPlantilla(plantillasEmail);
    }
  }, [dataBolsa]);

  const refrescaPlantilla = (template) => {
    if (dataBolsa && template) {
      form.setFieldsValue({
        de: `"${Usuario.n_usuario}" <${Usuario.email}>`,
        para: parseTemplate(template.email_para, dataBolsa),
        cc: parseTemplate(template.email_cc, dataBolsa),
        asunto: parseTemplate(template.email_asunto, dataBolsa),
        mensaje: parseTemplate(template.email_cuerpo, dataBolsa),
      });
    }
  };

  const fetchPlantillas = async () => {
    try {
/*       const dataValues = await apiGET(`/api/clientes/cliente/${id_cliente}/creditos/${id_bolsa}`);
      const dataUser = await apiGET(`/api/users/miperfil`);
      const data = await apiGET(`/api/email/plantillas`); */
      const [dataValues, dataUser, data] = await Promise.all([
        apiGET(`/api/clientes/cliente/${id_cliente}/creditos/${id_bolsa}`),
        apiGET(`/api/usuarios/miperfil/all`),
        apiGET(`/api/email/plantillas`)
      ]);

      setPlantillasEmail(data[0] || {});
      setDefaultPlantilla(data[0]?.id_plantilla || 0);
      setAllPlantillasEmail(data || []);
      setUsuario(dataUser[0] || []);

      if (dataValues[0]) {
        const values = {
          mi_email: dataUser[0].email,
          mi_nombre: dataUser[0].n_usuario,
          mi_posicion: dataUser[0].posicion,
          mi_movil: dataUser[0].movil,
          mi_telefono: dataUser[0].telefono,
          mi_firma: dataUser[0].firma,
          nombre_cliente: dataValues[0].nombre_cliente,
          contacto_cliente: dataValues[0].contacto_cliente,
          email_cliente: dataValues[0].email_cliente,
          posicion_cliente: dataValues[0].posicion_cliente,
          id_cliente: dataValues[0].id_cliente_sqpfirma,
          id_creditos_firma: dataValues[0].id_creditos_firma,
          id_cliente_sqpfirma: dataValues[0].id_cliente_sqpfirma,
          fecha_activacion: dayjs(dataValues[0].fecha_activacion).format('DD/MM/YYYY HH:mm'),
          fecha_caducidad: dayjs(dataValues[0].fecha_caducidad).format('DD/MM/YYYY HH:mm'),
          creditos_asignados: dataValues[0].creditos_asignados,
          creditos_acumulados: dataValues[0].creditos_acumulados,
          creditos_totales: dataValues[0].creditos_totales,
          notas: dataValues[0].notas,
          fecha_creacion: dayjs(dataValues[0].fecha_creacion).format('DD/MM/YYYY HH:mm'),
          fecha_modificacion: dayjs(dataValues[0].fecha_modificacion).format('DD/MM/YYYY HH:mm'),
          id_usuario_creacion: dataValues[0].id_usuario_creacion,
          id_usuario_modificacion: dataValues[0].id_usuario_modificacion,
          bolsa_sqp_sn: dataValues[0].bolsa_sqp_sn === 1 ? 'Si' : 'No',
          num_factura: dataValues[0].num_factura,
          sms_asignados: dataValues[0].sms_asignados,
          precio_credito: dataValues[0].precio_credito,
          importe_creditos: dataValues[0].importe_creditos,
          precio_sms: dataValues[0].precio_sms,
          importe_sms: dataValues[0].importe_sms,
          importe_total: dataValues[0].importe_total,
          renovacion: dataValues[0].renovacion,
        };
        setDataBolsa(values || {});
        //refrescaPlantilla();
      }
    } catch (error) {
      console.error('Error al cargar plantillas:', error);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (visible) {
        setLoading(true);
        form.resetFields();
        await fetchPlantillas();
        setLoading(false);
      }
    };
    loadData();
  }, [visible]);


  const handleOk = async () => {
    let values;
  
    try {
      // Validar los campos del formulario
      values = await form.validateFields();
    } catch (info) {
      console.log('Validate Failed:', info);
      return;
    }
  
    setLoadingMsg('Enviando email ...');
    setLoading(true);
  
    try {
      const url = `/api/email/send`;
      const data = await apiPOST(url, values);
  
      // Notificación de éxito
      notification.success({
        message: 'Email enviado',
        description: 'El email se ha enviado correctamente.',
      });
  
      console.log('Email enviado', data);
      form.resetFields();
      onCreate();

    } catch (error) {
      console.error('Error al enviar email', error);
      // Notificación de error
      notification.error({
        message: 'Error al enviar el email.',
        description: 'No se pudo enviar el email. Por favor, inténtelo de nuevo.',
      });


    } finally {
      // Asegúrate de detener el indicador de carga, independientemente del resultado
      setLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    onCreate();
  };

  const handleTemplateChange = (e) => {
    if (!allPlantillasEmail) {
      return;
    }
    const template = allPlantillasEmail.find((x) => x.id_plantilla == e.target.value);
    console.log(`Plantilla seleccionada: ${e.target.value}`, template.id_plantilla);
    setPlantillasEmail(template);
    refrescaPlantilla(template);
  };

  const labelForm = 'Nueva solicitud por email';

  const quillModules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [{ align: [] }],
      ['clean'], // Botón para limpiar el formato
    ],
  };

  return (
    <Modal
      title={labelForm}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      width={1000}
      footer={() => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            icon={<EditOutlined />}
            key="back"
            onClick={() => setModalEnviaEmailVisible(true)}
            disabled={loading}
          >
            Editar plantilla
          </Button>

          <div style={{ flex: '1' }}></div>

          <Button key="back" onClick={onCancel} disabled={loading}>
            Cancelar
          </Button>
          <Button key="submit" type="primary" onClick={handleOk} disabled={loading}>
            Enviar
          </Button>
        </div>
      )}
      forceRender
    >
      {!loading ? (
        <Form form={form} className="modalEmail" size="medium">
          <div className="modalHeaderMargin"></div>
          <Radio.Group
            defaultValue={defaultPlantilla}
            size="large"
            style={{ display: 'flex' }}
            onChange={handleTemplateChange}
          >
            {allPlantillasEmail.map((plantilla) => (
              <Radio.Button key={plantilla.id_plantilla} value={plantilla.id_plantilla} style={{ flex: 1, textAlign: 'center' }}>
                {plantilla.n_plantilla}
              </Radio.Button>
            ))}
          </Radio.Group>

          <div className="modalHeaderMargin"></div>
          <Form.Item name="de" label="De" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="para" label="Para" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="cc" label="Cc" >
            <Input />
          </Form.Item>
          <Form.Item name="asunto" label="Asunto" rules={[{ required: true }]}>
            <Input placeholder="Asunto" />
          </Form.Item>
          <Form.Item name="mensaje">
           {/*  <ReactQuill
              theme="snow"
              modules={quillModules}
              value={form.getFieldValue('mensaje')}
              onChange={(content, delta, source, editor) => {
                form.setFieldsValue({ mensaje: editor.getHTML() });
              }}
            /> */}
             <QuillEditor theme="snow" value={form.getFieldValue('mensaje')} onChange={(value) => form.setFieldsValue({ mensaje: value })} />
          </Form.Item>
        </Form>
      ) : (
        <Space style={{ margin: '20px 0', width: '100%' }}>
          <Spin spinning={true}></Spin>
          {loadingMsg}
        </Space>
      )}
      {modalEnviaEmailVisible && (
        <ModalPlantillaEmail
          visible={modalEnviaEmailVisible}
          plantillaId={plantillasEmail.id_plantilla}
          onCreate={() => setModalEnviaEmailVisible(false)}
        />
      )}
    </Modal>
  );
};

export default ModalEnviaEmail;
