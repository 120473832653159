import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Select, InputNumber, DatePicker, Space, Checkbox, Row, Col, Popover  } from 'antd';
import { InfoCircleOutlined,ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/es';
import { useApi } from '../ApiContext';
import dayjs from 'dayjs';

moment.locale('es');

const { Option } = Select;
const { TextArea } = Input;

const ModalBolsaCreditos = ({ id_cliente, id_bolsa, accion, visible, onCancel, onCreate }) => {
  const { apiGET, apiPOST, apiPUT, apiDELETE } = useApi();
  const [form] = Form.useForm();
  const [bolsaCreditos, setBolsaCreditos] = useState({});
  const [updating, setUpdating] = useState(false);
  const dateFormat = 'DD-MM-YYYY';


  useEffect(() => {
    if (visible && id_cliente && id_bolsa) {
      const fetchBolsasCliente = async () => {
        try {
          const data = await apiGET(`/api/clientes/cliente/${id_cliente}/creditos/${id_bolsa}`);

          setBolsaCreditos(data[0] || {});
console.log(data[0])
          form.setFieldsValue({
            fecha_activacion: data[0] ? dayjs(data[0].fecha_activacion) : null,
            fecha_caducidad: data[0] ? dayjs(data[0].fecha_caducidad) : null,
            creditos_asignados: data[0] ? data[0].creditos_asignados : null,
            notas: data[0] ? data[0].notas : null,
            creditos_acumulados: data[0] ? data[0].creditos_acumulados : null,
            creditos_totales: data[0] ? data[0].creditos_totales : null,
            fecha_creacion: data[0] ? dayjs(data[0].fecha_creacion) : null,
            fecha_modificacion: data[0] ? dayjs(data[0].fecha_modificacion) : null,
            n_usuario_creacion: data[0] ? data[0].n_usuario_creacion : null,
            n_usuario_modificacion: data[0] ? data[0].n_usuario_modificacion : null,
            importe_creditos: data[0] ? data[0].importe_creditos : null,
            importe_sms: data[0] ? data[0].importe_sms : null,
            importe_total: data[0] ? data[0].importe_total : null,
            num_factura: data[0] ? data[0].num_factura : null,
            precio_credito: data[0] ? data[0].precio_credito : null,
            precio_sms: data[0] ? data[0].precio_sms : null,
            sms_asignados: data[0] ? data[0].sms_asignados : null,
            renovacion: data[0] ? data[0].renovacion : null,
            bolsa_sqp_sn: data[0] ? (data[0].bolsa_sqp_sn === 1) : false,
          }); 
          console.log('x',data[0]?.bolsa_sqp_sn)
        } catch (error) {
          console.error("Error al cargar resumen creditos:", error);
        }
      };

      fetchBolsasCliente();
    } else if (visible && accion === 'new') {
      form.resetFields();
      form.setFieldsValue({ bolsa_sqp_sn: true });
      form.setFieldsValue({ renovacion: 'A' });
      form.setFieldsValue({ fecha_activacion: dayjs() });
      setEndDate();
    }
  }, [visible, id_cliente, id_bolsa, accion, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const adjustedValues = {
          ...values,
          fecha_activacion: values.fecha_activacion ? values.fecha_activacion.format('YYYY-MM-DD') : null,
          fecha_caducidad: values.fecha_caducidad ? values.fecha_caducidad.format('YYYY-MM-DD') : null,
          fecha_creacion: values.fecha_creacion ? values.fecha_creacion.format('YYYY-MM-DD') : null,
          fecha_modificacion: values.fecha_modificacion ? values.fecha_modificacion.format('YYYY-MM-DD') : null,
          bolsa_sqp_sn: values.bolsa_sqp_sn ? 1 : 0,
        };
console.log('ZZ',values.bolsa_sqp_sn)
        form.resetFields();
        onCreate(id_bolsa, adjustedValues, accion);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  let labelForm = accion === 'new' ? 'Nueva bolsa de créditos' : 'Editar bolsa de créditos';
  const fecha_creacion = form.getFieldValue('fecha_creacion');
  const n_usuario_creacion = form.getFieldValue('n_usuario_creacion');
  const fecha_modificacion = form.getFieldValue('fecha_modificacion');
  const n_usuario_modificacion = form.getFieldValue('n_usuario_modificacion');

  const infoForm = (
    <>
      {fecha_creacion && n_usuario_creacion && (
        <div>Creado el {fecha_creacion.format('DD/MM/YYYY')} por {n_usuario_creacion}</div>
      )}
      {fecha_modificacion && n_usuario_modificacion && (
        <div>Última modificación el {fecha_modificacion.format('DD/MM/YYYY')} por {n_usuario_modificacion}</div>
      )}
    </>
  );

  const titleWithPopover = (
    <span>
      {labelForm}
      <Popover content={infoForm}>
        <InfoCircleOutlined style={{ marginLeft: 5 }} />
      </Popover>
    </span>
  );

  const setImporteTotal = () => {
    const importeCreditos = parseFloat(form.getFieldValue('importe_creditos')) || 0;
    const importeSMS = parseFloat(form.getFieldValue('importe_sms')) || 0;
    console.log(importeCreditos.toFixed(2),importeSMS.toFixed(2))
    const importeTotal = (importeCreditos + importeSMS) ? (importeCreditos + importeSMS).toFixed(2) : null;

    form.setFieldsValue({ importe_total: importeTotal });
    console.log(importeTotal)
  }

  
  const setEndDate = () => {
    const fechaActivacion = form.getFieldValue('fecha_activacion');
    if (fechaActivacion) {
      const fechaCaducidad = dayjs(fechaActivacion).add(1, 'year').subtract(1, 'day');
      form.setFieldsValue({ fecha_caducidad: fechaCaducidad });
    }
  };

  const handleSelectChange = (value) => {
    console.log('Selected:', value);
    if (value == 'A') {
      setEndDate();
    } else {
      form.setFieldsValue({ fecha_caducidad: null });
    }
  };

  const handleFechaActivacionChange = () => {
    if (renovacion == 'A'){
      setEndDate();
    }
  }


  const handlePrecioCreditoChange = () => {
    if (!updating) {
      setUpdating(true);
      const precio = parseFloat(form.getFieldValue('precio_credito')) || 0;
      const unidades = parseFloat(form.getFieldValue('creditos_asignados')) || 0;
      if (unidades && precio){
        let importe = precio * unidades;
        form.setFieldsValue({ importe_creditos: importe.toFixed(2) });
      } else {
        form.setFieldsValue({ importe_creditos: null });
      }
      setImporteTotal();
      setUpdating(false);
    }
  }
  const handleImporteCreditoChange = () => {
    if (!updating) {
      setUpdating(true);
      const importe = parseFloat(form.getFieldValue('importe_creditos')) || 0;
      const unidades = parseFloat(form.getFieldValue('creditos_asignados')) || 0;
      if (unidades && importe){
        let precio = importe / unidades;
        form.setFieldsValue({ precio_credito: precio.toFixed(2) });
      } else {
        form.setFieldsValue({ precio_credito: null });
      }
      setImporteTotal();
      setUpdating(false);
    }
  }
  const handlePrecioSMSChange = () => {
    if (!updating) {
      setUpdating(true);
      const precio = parseFloat(form.getFieldValue('precio_sms')) || 0;
      const unidades = parseFloat(form.getFieldValue('sms_asignados')) || 0;
      if (unidades && precio){
        let importe = precio * unidades;
        form.setFieldsValue({ importe_sms: importe.toFixed(2) });
      } else {
        form.setFieldsValue({ importe_sms: null });
      }
      setImporteTotal();
      setUpdating(false);
    }
  }
  const handleImporteSMSChange = () => {
    if (!updating) {
      setUpdating(true);
      const importe = parseFloat(form.getFieldValue('importe_sms')) || 0;
      const unidades = parseFloat(form.getFieldValue('sms_asignados')) || 0;;
      if (unidades && importe){
        let precio = importe / unidades;
        form.setFieldsValue({ precio_sms: precio.toFixed(2) });
      } else {
        form.setFieldsValue({ precio_sms: null });
      }
      setImporteTotal(); console.log('si')
      setUpdating(false);
    }
  }

  const renovacion = Form.useWatch('renovacion', form);


  return (
    <Modal title={titleWithPopover} visible={visible} onOk={handleOk} onCancel={onCancel} forceRender>
      <Form  form={form} layout="vertical" name="bolsaCreditosForm">
        <div style={{minHeight:'20px'}}></div>

        <div style={{display:'inline-flex', gap:'10px', width:'100%'}}>
            <Form.Item 
              style={{width:'120px'}}
              name="fecha_activacion" label="Activación" 
              rules={[{ required: true, message: 'Por favor, seleccione la fecha de activación.' }]}>
              <DatePicker 
                format={dateFormat} 
                onChange={handleFechaActivacionChange}
              />
            </Form.Item>            
            <Form.Item 
              style={{width:'120px'}}
              name="fecha_caducidad" label="Caducidad" 
              rules={[{ required: (renovacion != 'X'), message: 'Por favor, seleccione la fecha de caducidad.' }]}>
              <DatePicker 
                format={dateFormat} 
                disabled={renovacion !== 'M'}  
                className={renovacion !== 'M' ? 'custom-disabled-datepicker' : ''}
              />
            </Form.Item>
            <Form.Item 
              style={{width:'120px'}}
              name="renovacion">  
              <Select 
                style={{width:'212px',xflex: '1', marginTop: '30.67px' }}
                placeholder="Seleccione el tipo de caducidad" 
                onChange={handleSelectChange}>
                <Option value="A">Anual</Option>
                <Option value="M">Manual</Option>
                <Option value="X">Sin caducidad</Option>
            </Select>
           </Form.Item>
        </div>

            
        <Form.Item _label="Bolsa Squarepoint" name="bolsa_sqp_sn" valuePropName="checked">
          <Checkbox _defaultChecked name="bolsa_sqp_sn" className='custom-checkbox-with-label'>
            <div>Bolsa Squarepoint</div>
            

        <div style={{ fontSize: '13px', color:'grey', _marginLeft:'25px', _width:'100%' }}> Los créditos y SMS asignados se descuentan de la bolsa general de Squarepoint.</div>
          </Checkbox>
        </Form.Item>
        <Form.Item name="num_factura" label="Número de factura">
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="creditos_asignados" label="Créditos asignados" rules={[{ required: true, message: 'Por favor, ingrese los créditos asignados.' }]}>
              <InputNumber onChange={handlePrecioCreditoChange} _min={0} max={90000} step={1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="precio_credito" label="Precio crédito">
              <InputNumber onChange={handlePrecioCreditoChange} min={0} step={0.01} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="importe_creditos" label="Importe créditos">
              <InputNumber onChange={handleImporteCreditoChange} min={0}  step={0.01} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="sms_asignados" label="SMS asignados">
              <InputNumber onChange={handlePrecioSMSChange} max={90000} step={1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="precio_sms" label="Precio SMS">
              <InputNumber onChange={handlePrecioSMSChange} min={0} step={0.10} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="importe_sms" label="Importe SMS">
              <InputNumber onChange={handleImporteSMSChange} min={0} step={1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item name="importe_total" label={<span style={{ fontWeight: 'bold' }}>Importe Total</span>}>
              <InputNumber disabled={true} step={0.01} className={'custom-disabled-datepicker'} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="notas" label="Notas">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalBolsaCreditos;
