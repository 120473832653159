// sesion.js

let token = null; // Almacenamiento privado del token

// Establece el token
export const setToken = newToken => {
  token = newToken;
};

// Obtiene el token
export const getToken = () => token;
