import React from 'react';
import { Card, Popover, Button, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined, MailOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/es';

const UserCard = ({ user, handleDelete, handleUpdate }) => {
  const formatDate = (dateString) => {
    return dateString ? moment(dateString).format('L LTS') : 'N/A';
  };

  const content = (
    <div>
      <p><b>Email:</b> {user.email}</p>
      <p><b>Creación:</b> {formatDate(user.fecha_creacion)}</p>
      <p><b>Último Login:</b> {formatDate(user.fecha_ultimo_login)}</p>
      <p><b>Estado:</b> {user.estado_usuario}</p>
      <p><b>Posición:</b> {user.posicion}</p>
      <p><b>Móvil:</b> {user.movil}</p>
      <p><b>Teléfono:</b> {user.telefono}</p>
    </div>
  );

  return (
    <div style={{ margin: '0px', width: '23%', 'min-width':'290px' }}>
      <Card
        title={user.n_usuario}
        bordered={false}
        actions={[
          <EditOutlined key="edit" onClick={() => handleUpdate(user.id_usuario)} />,
          <DeleteOutlined key="delete" onClick={() => handleDelete(user.id_usuario)} />,
          <Popover content={content} title="Información" trigger="hover">
            <InfoCircleOutlined key="info" />
          </Popover>,
        ]}
      >
        <div>
          <a href={`mailto:${user.email}`} target="_blank" rel="noopener noreferrer">
            <MailOutlined /> {user.email}
          </a>
        </div>

        <div style={{ margin: '15px 0 0 0' }}>
          <Tag color="blue">{user.tipo_usuario}</Tag>
          <Tag color="green">{user.estado_usuario}</Tag>
        </div>
      </Card>
    </div>
  );
};

export default UserCard;
