import React from 'react';
import { Button, Row, Col, Card } from 'antd';
import axios from 'axios';
import { GoogleOutlined } from '@ant-design/icons';

const LoginPage = () => {
  const handleLogin = async () => {
    try {
      // Hacer una solicitud al backend para obtener la URL de autenticación
      const response = await axios.get('/auth/google');
      if (response.data.url) {
        // Redirige al usuario a la URL de autenticación de Google
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error('Hubo un problema con la solicitud:', error);
    }
  };

  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
      <Card>
        <Row justify="center" style={{ marginBottom: '60px' }}>
          <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <img src="/logo-squarepoint_2.svg" alt="Logo" style={{ width: '100%', maxWidth: '100%', maxHeight: '100px' }} />
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Button
              type=""
              icon={<GoogleOutlined />}
              onClick={handleLogin}
            >
              Iniciar sesión con Google
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export { LoginPage };
