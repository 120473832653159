import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Space, Spin, Table, Tooltip, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { TagOutlined  } from '@ant-design/icons';
import { useApi } from '../ApiContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillEditor from '../common/QuillEditor';

const { TextArea } = Input;

const ModalPlantillaEmail = ({ visible, onCreate, plantillaId }) => {
  const { apiGET, apiPUT } = useApi();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [plantilla, setPlantilla] = useState({});
  const [tagsVisible, setTagsVisible] = useState(false);

  const tags = [
    { tag: 'mi_email', description: 'Correo electrónico del usuario.' },
    { tag: 'mi_nombre', description: 'Nombre de usuario.' },
    { tag: 'mi_posicion', description: 'Posición del usuario.' },
    { tag: 'mi_movil', description: 'Número de móvil del usuario.' },
    { tag: 'mi_telefono', description: 'Número de teléfono del usuario.' },
    { tag: '{mi_firma}', description: 'Firma del usuario.' },
    { tag: 'nombre_cliente', description: 'Nombre del cliente.' },
    { tag: 'contacto_cliente', description: 'Información de contacto del cliente.' },
    { tag: 'email_cliente', description: 'Correo electrónico del cliente.' },
    { tag: 'posicion_cliente', description: 'Posición del cliente.' },
    { tag: 'id_cliente', description: 'Identificador único del cliente (en firma).' },
    { tag: 'id_creditos_firma', description: 'Identificador de créditos asociado a la firma.' },
    { tag: 'id_cliente_sqpfirma', description: 'Identificador del cliente (en Hire&Sign).' },
    { tag: 'fecha_activacion', description: 'Fecha de activación del cliente (formateada).' },
    { tag: 'fecha_caducidad', description: 'Fecha de caducidad del cliente (formateada).' },
    { tag: 'creditos_asignados', description: 'Créditos asignados al cliente.' },
    { tag: 'creditos_acumulados', description: 'Créditos acumulados por el cliente.' },
    { tag: 'creditos_totales', description: 'Créditos totales disponibles para el cliente.' },
    { tag: 'notas', description: 'Notas adicionales sobre el cliente.' },
    { tag: 'fecha_creacion', description: 'Fecha de creación del registro (formateada).' },
    { tag: 'fecha_modificacion', description: 'Fecha de la última modificación del registro (formateada).' },
    { tag: 'id_usuario_creacion', description: 'Identificador del usuario que creó el registro.' },
    { tag: 'id_usuario_modificacion', description: 'Identificador del usuario que modificó el registro.' },
    { tag: 'bolsa_sqp_sn', description: 'Indica si la asignación de creditos resta de la bolsa de Squarepoint (Sí/No).' },
    { tag: 'num_factura', description: 'Número de factura asociado.' },
    { tag: 'sms_asignados', description: 'Número de SMS asignados.' },
    { tag: 'precio_credito', description: 'Precio por crédito.' },
    { tag: 'importe_creditos', description: 'Importe total de créditos.' },
    { tag: 'precio_sms', description: 'Precio por SMS.' },
    { tag: 'importe_sms', description: 'Importe total de SMS.' },
    { tag: 'importe_total', description: 'Importe total de la transacción.' },
    { tag: 'renovacion', description: 'Indicador de renovación.' }
  ];

  const fetchPlantilla = async () => {
    try {
      setLoading(true);
      const data = await apiGET(`/api/email/plantillas/${plantillaId}`);
      const plantillaData = data[0] || {};
      setPlantilla(plantillaData);
      form.setFieldsValue({
        n_plantilla: plantillaData.n_plantilla || '',
        email_para: plantillaData.email_para || '',
        email_cc: plantillaData.email_cc || '',
        email_asunto: plantillaData.email_asunto || '',
        email_cuerpo: plantillaData.email_cuerpo || '',
      });
      setLoading(false);
    } catch (error) {
      console.error("Error al cargar la plantilla:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible && plantillaId) {
      form.resetFields();
      fetchPlantilla();
    }
  }, [visible, plantillaId]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      try {
        const url = `/api/email/plantillas/${plantillaId}`;
        await apiPUT(url, values);
        console.log('Plantilla actualizada');
        form.resetFields();
        onCreate();
      } catch (error) {
        console.error('Error al actualizar la plantilla', error);
        throw new Error('Error al actualizar la plantilla');
      }
    } catch (info) {
      console.log('Validate Failed:', info);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    onCreate();
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('Tag copiado al portapapeles');
    }).catch(() => {
      message.error('Error al copiar el tag');
    });
  };

  const columns = [
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
      render: text => (
        <Tooltip title="Copiar tag">
          <span style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(`{{${text}}}`)}>
            {`{{${text}}}`} <CopyOutlined />
          </span>
        </Tooltip>
      )
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    }
  ];

  const labelForm = 'Editar Plantilla de Email';

  return (
    <>
      <Modal 
        title={labelForm} 
        visible={visible} 
        onOk={handleOk} 
        onCancel={onCancel} 
        footer={() => (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              icon={<TagOutlined  />} key="tags" type="default" onClick={() => setTagsVisible(true)} disabled={loading}
            >
              Tags
            </Button>
  
            <div style={{ flex: '1' }}></div>
  
            <Button key="back" onClick={onCancel} disabled={loading}>
            Cancelar
          </Button>
          <Button key="submit" type="primary" onClick={handleOk} disabled={loading}>
            Guardar
          </Button>
          </div>
        )}
        forceRender
      >
        {!loading ? (
          <Form form={form} className='modalEmail' size="medium">
            <div className='modalHeaderMargin'></div>
            <Form.Item  name="n_plantilla" _label="Nombre ">
              <Input size="large" style={{ textAlign: 'center' }}/>
            </Form.Item>
            <div className='modalHeaderMargin'></div>
            <Form.Item name="email_para" label="Para" rules={[{ required: false, type: 'email' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email_cc" label="Cc" rules={[{ type: 'email' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email_asunto" label="Asunto" rules={[{ required: false }]}>
              <Input placeholder="Asunto"/>
            </Form.Item>
            <Form.Item name="email_cuerpo">
              <QuillEditor theme="snow" value={form.getFieldValue('email_cuerpo')} onChange={(value) => form.setFieldsValue({ email_cuerpo: value })} />
            </Form.Item>
          </Form>
        ) : (
          <Space style={{ margin: '20px 0', width: '100%' }}>
            <Spin spinning={true}></Spin>
            {loading && <div>{'cargando...'}</div>}
          </Space>
        )}
      </Modal>

      <Modal
        title="Lista de Tags Disponibles"
        visible={tagsVisible}
        onOk={() => setTagsVisible(false)}
        onCancel={() => setTagsVisible(false)}
        footer={[
          <Button key="close" type="primary" onClick={() => setTagsVisible(false)}>
            Cerrar
          </Button>
        ]}
      >
        <Table 
          className='cfa_table_tags'
          columns={columns} 
          dataSource={tags.map(tag => ({ key: tag.tag, ...tag }))} 
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default ModalPlantillaEmail;
