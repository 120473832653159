import { format, isValid  } from 'date-fns';
import { getToken } from './sesion';

function formatDateString(dateString) {

    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);
    if (isValid(date)) {
      // Formatea la fecha como prefieras. Aquí usamos el formato 'dd/MM/yyyy'.
      return format(date, 'dd/MM/yyyy');
    } else {
      // Maneja el caso de fecha inválida como prefieras
      return 'Fecha inválida';
    }
};

function formatNegativeNumbers(numero, red=0, orange=1, decimales=2) {


    const valuenumber = parseFloat(numero?.toFixed(decimales));
    if (isNaN(valuenumber)) { 
        return null
    }

    const isNegativeRed = valuenumber < red;
    const isNegativeOrange = valuenumber <= orange;
    let res;

    if (isNegativeRed) {
      res = (
        <span style={{ color: 'red' }}>
          {valuenumber}
        </span>
      )
    } else {
      res = (
        <span style={{ color: isNegativeOrange ? 'orange': 'inherit' }}>
          {valuenumber}
        </span>
      )
    }

    return res;
};

// async function apiPOST(url, datos, options = {}) {
//     const token = getToken().token;
//     console.log('post', token)
//     const headers = new Headers(options.headers || {});
//     headers.append('Authorization', `Bearer ${token}`);
//     headers.append('Content-Type', `application/json`);


//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers,
//         body: JSON.stringify(datos),
//       });
  
//       // Intenta leer el cuerpo de la respuesta como JSON, si falla, intenta como texto plano
//       let responseBody;
//       try {
//         responseBody = await response.json();
//       } catch (error) {
//         responseBody = await response.text();
//       }
  
//       if (!response.ok) {
//         // Construye un mensaje de error basado en el cuerpo de la respuesta
//         const errorMessage = typeof responseBody === 'object' ? responseBody.message : responseBody;
//         const errorDetail = errorMessage || `Error desconocido con estado: ${response.status}`;
//         throw new Error(`Error en la solicitud a ${url}: ${errorDetail}`);
//       }
  
//       return responseBody; // Retorna el cuerpo de la respuesta para respuestas exitosas
//     } catch (error) {
//       console.error('Hubo un problema con la solicitud fetch:', error.message);
//       // Aquí puedes decidir si quieres re-lanzar el error o manejarlo de alguna manera
//       throw error;
//     }
//   }

// async function apiPUT(url, datos, options = {}) {
//     const token = getToken().token;

//     const headers = new Headers(options.headers || {});
//     headers.append('Authorization', `Bearer ${token}`);
//     headers.append('Content-Type', `application/json`);


//     try {
//         const response = await fetch(url, {
//         method: 'PUT',
//         headers,
//         body: JSON.stringify(datos),
//         });

//         // Intenta leer el cuerpo de la respuesta como JSON, si falla, intenta como texto plano
//         let responseBody;
//         try {
//         responseBody = await response.json();
//         } catch (error) {
//         responseBody = await response.text();
//         }

//         if (!response.ok) {
//         // Construye un mensaje de error basado en el cuerpo de la respuesta
//         const errorMessage = typeof responseBody === 'object' ? responseBody.message : responseBody;
//         const errorDetail = errorMessage || `Error desconocido con estado: ${response.status}`;
//         throw new Error(`Error en la solicitud a ${url}: ${errorDetail}`);
//         }

//         return responseBody; // Retorna el cuerpo de la respuesta para respuestas exitosas
//     } catch (error) {
//         console.error('Hubo un problema con la solicitud fetch:', error.message);
//         // Aquí puedes decidir si quieres re-lanzar el error o manejarlo de alguna manera
//         throw error;
//     }
// }  

// async function apiDELETE(url, datos, options = {}) {
//     const token = getToken().token;

//     const headers = new Headers(options.headers || {});
//     headers.append('Authorization', `Bearer ${token}`);
//     headers.append('Content-Type', `application/json`);
  
//     try {
//         const response = await fetch(url, {
//         method: 'DELETE',
//         headers,
//         body: JSON.stringify(datos),
//         });

//         // Intenta leer el cuerpo de la respuesta como JSON, si falla, intenta como texto plano
//         let responseBody;
//         try {
//         responseBody = await response.json();
//         } catch (error) {
//         responseBody = await response.text();
//         }

//         if (!response.ok) {
//         // Construye un mensaje de error basado en el cuerpo de la respuesta
//         const errorMessage = typeof responseBody === 'object' ? responseBody.message : responseBody;
//         const errorDetail = errorMessage || `Error desconocido con estado: ${response.status}`;
//         throw new Error(`Error en la solicitud a ${url}: ${errorDetail}`);
//         }

//         return responseBody; // Retorna el cuerpo de la respuesta para respuestas exitosas
//     } catch (error) {
//         console.error('Hubo un problema con la solicitud fetch:', error.message);
//         // Aquí puedes decidir si quieres re-lanzar el error o manejarlo de alguna manera
//         throw error;
//     }
// }  

// async function apiGET(url, options = {}) {

//     const token = getToken().token;
//     const headers = new Headers(options.headers || {});
//     headers.append('Authorization', `Bearer ${token}`);
//     headers.append('Content-Type', `application/json`);



//     // Realiza la solicitud con fetch, incluyendo el token en los headers
//     const response = await fetch(url, {headers});
//     if (!response.ok) {
//         throw new Error('La solicitud falló');
//     }
//     return response.json(); // Asumimos que el servidor siempre responde con JSON
// }

export {formatDateString,formatNegativeNumbers}