import Icon from '@ant-design/icons';


const HSsvg1 = () => (

    <svg width="16" height="16" viewBox="0 0 268 268" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f_7165_46335)">
<path d="M237.5 157.663V238.488L156.981 238.355L237.5 157.663ZM30.5 158.855C30.5 88.1042 89.0287 30.5 161.538 30.5C189.623 30.5 215.617 39.1467 236.929 53.8578V107.426L105.688 238.325H58.6298C41.001 216.454 30.5 188.858 30.5 158.855Z" stroke="white" stroke-width="15" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_f_7165_46335" x="22" y="22" width="224" height="225" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_7165_46335"/>
</filter>
</defs>
</svg>

)

const HSsvg = () => (
    <svg width="14" height="14" viewBox="0 0 192 197" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M191.493 196.154L100 196L191.493 102.5V196.154Z" fill="#ffffff75"/>
      <path d="M0 119.5C0 53.502 53.502 0 119.5 0C146.312 0 171.062 8.83028 191 23.7414V77L74 196H27.692C10.4025 175.273 0 148.602 0 119.5Z" fill="#FFF"/>
    </svg>
  )
  const HSIcon = (props) => <Icon component={HSsvg1} {...props} />;

export default HSIcon;