import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importa los estilos por defecto de ReactQuill
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useApi } from '../ApiContext';
import dayjs from 'dayjs';

const SiteFormModal = ({ siteId, accion, visible, onCancel, onCreate  }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isEditMode = Boolean(siteId); // Determina si el formulario está en modo edición basado en si siteId está presente
  const { apiGET,apiPOST,apiPUT,apiDELETE } = useApi();
  const [site, setSite] = useState({});

  useEffect(() => {
    if (visible && siteId) {
      const fetchSite = async () => {
        try {
          const data = await apiGET(`/api/websites/${siteId}`);
         
          setSite(data[0] || {});

          form.setFieldsValue({
            nombre: data[0] ? data[0].nombre : null,
            link: data[0] ? data[0].link : null,
            grupo: data[0] ? data[0].grupo : null,
            entorno: data[0] ? data[0].entorno : null,
            notas: data[0] ? data[0].notas : null,
          });
        } catch (error) {
          console.error("Error al cargar el entorno:", error);
        }
      };

      fetchSite();
    } else if (visible && accion === 'new') {
      form.resetFields();
    }
  }, [visible, siteId, accion, form]);

  const handleOk = () => {
    form
    .validateFields()
    .then((values) => {

      form.resetFields();
      onCreate(siteId, values, accion);
    })
    .catch((info) => {
      console.log('Validate Failed:', info);
    });
  };

  return (
    <Modal
      title={isEditMode ? 'Modificar Entorno' : 'Añadir Nuevo Entorno'}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      forceRender // Forza el renderizado para manejar el estado interno correctamente
    >
      <Form form={form} layout="vertical" style={{ margin: '30px 0' }}>
        <Form.Item
          name="nombre"
          label="Nombre"
          rules={[{ required: true, message: 'Por favor, ingrese el nombre del entorno.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="link"
          label="Link"
          rules={[{ required: true, message: 'Por favor, ingrese el link del entorno.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="grupo"
          label="Grupo"
          rules={[{ required: true, message: 'Por favor, ingrese el grupo.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="entorno"
          label="Entorno"
          rules={[{ required: true, message: 'Por favor, ingrese el entorno.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="notas"
          label="Notas"
        >
          {/* Integración con ReactQuill dentro de Form.Item de AntD */}
          <ReactQuill theme="snow" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SiteFormModal;
