import React from 'react';
import { Card } from 'antd';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LabelList, ResponsiveContainer } from 'recharts';
const data_x = [
    { "id_cliente_sqpfirma": 5, "anio": 2024, "mes": 7, "firmas_signaturit": 592 },
    { "id_cliente_sqpfirma": 5, "anio": 2024, "mes": 6, "firmas_signaturit": 1058 },
    { "id_cliente_sqpfirma": 5, "anio": 2024, "mes": 5, "firmas_signaturit": 843 },
    { "id_cliente_sqpfirma": 5, "anio": 2024, "mes": 4, "firmas_signaturit": 1084 },
    { "id_cliente_sqpfirma": 5, "anio": 2024, "mes": 3, "firmas_signaturit": 1276 },
    { "id_cliente_sqpfirma": 5, "anio": 2024, "mes": 2, "firmas_signaturit": 1360 },
    { "id_cliente_sqpfirma": 5, "anio": 2024, "mes": 1, "firmas_signaturit": 2850 },
    { "id_cliente_sqpfirma": 5, "anio": 2023, "mes": 12, "firmas_signaturit": 1874 },
    { "id_cliente_sqpfirma": 5, "anio": 2023, "mes": 11, "firmas_signaturit": 1743 },
    { "id_cliente_sqpfirma": 5, "anio": 2023, "mes": 10, "firmas_signaturit": 1987 },
    { "id_cliente_sqpfirma": 5, "anio": 2023, "mes": 9, "firmas_signaturit": 1164 },
    { "id_cliente_sqpfirma": 5, "anio": 2023, "mes": 8, "firmas_signaturit": 1873 },
    { "id_cliente_sqpfirma": 5, "anio": 2023, "mes": 7, "firmas_signaturit": 1558 }
];


const ConsumoBarChart = ({ data }) => (
    <Card title="Consumo Mensual Créditos de Firma">
        <ResponsiveContainer _width="100%" height={180}>
            <BarChart data={data} margin={{ top: 15, right: 0, left: -20, bottom: 2 }}>
                <defs>
                    <linearGradient id="colorConsumo" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.9} />
                        <stop offset="99%" stopColor="#82ca9d" stopOpacity={0.3} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="aniomes"  style={{ fontSize: '9px' }}  />
                <YAxis  style={{ fontSize: '9px' }}/>
                <Tooltip contentStyle={{ fontSize: '10px' }}/> 
                <Legend wrapperStyle={{ fontSize: '10px' }}/>
                <Bar 
                    dataKey="firmas_signaturit" 
                    fill="url(#colorConsumo)" 
                    name="Consumo créditos"
                    
                >
                <LabelList dataKey="firmas_signaturit" position="top"  style={{ fontSize: '9px' }} />
                </Bar> 
            </BarChart>
        </ResponsiveContainer>
    </Card>
);

export default ConsumoBarChart;
